import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import {SongPageComponent} from './song-pages/song-page/song-page.component'


const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./song-pages/song-pages.module').then(m => m.SongPagesModule)
  },
  {
    path: 'aboutus',
    component: AboutUsComponent
  }
  

];


@NgModule({
  declarations: [],
  imports: [

    RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
