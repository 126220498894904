<header style="min-height: 1000px" class="masthead text-center text-white"> 
<div class="about-section">
    <h1>Island Vibe Records Global</h1>
    <p>We are an up and coming label dedicated to taking the sounds of Saint Martin to a global platform and stage. Our aim is to reach corners of the globe never reached before. 
    </p>
    <p>Island Vibe believes in joint efforts - bringing artists and their own unique sound together to spread the love of music and to showcase talent from a small island. Originating out of the Caribbean from the small 37 square mile island of Saint Martin/Sint Maarten, we intend to explore every possible method of achieving global success through music. </p>
  </div>
  
  <h2 style="text-align:center; margin-bottom: 30px;">Our Team</h2>
  <div class="row" style="margin-left:30%; margin-right:30%;">
    <div class="column">
        <img src="../../assets/alex.jpg" style="width:50%">
        <div class="container">
          <h2 style="margin-top: 30px;">Alexander Henrietta</h2>
          <p class="title">Founder & President</p>
        </div>
    </div>
  
    <div class="column">
        <img src="../../assets/IMG_4882.jpg"  style="width:100%">
        <div class="container">
          <h2 style="margin-top: 30px;">MAGICX</h2>
          <p class="title">CEO & Artist</p>
        </div>
    </div>
  </div>
</header>